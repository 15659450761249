












































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { EmpresaService, TipoDocumentoService } from '@/core/services/geral';
import { PageBase } from '@/core/models/shared';
import { EmpresaDocumento } from '@/core/models/geral/EmpresaDocumento';
import { ArquivoService } from '@/core/services/shared';
import { TipoDocumento } from '@/core/models/geral';

@Component
export default class ListaDocumentoEmpresa extends PageBase {
  search: any = '';
  loading: boolean = false;
  loadingDownload: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Identificação', value: 'documento.identificacao' },
    { text: 'Descrição', value: 'documento.descricao' },
    { text: 'Tipo', value: 'documento.tipo.nome' },
    { text: 'Arquivo', value: 'documento.arquivo.nome' },
    { text: 'Validade', value: 'documento.dataValidade', type:'date' },
  ];
  item = new EmpresaDocumento();
  service = new EmpresaService();
  sheet: boolean = false;
  filtro: any = {
    identificacao: null,
    descricao: null,
    tipoId: null,
    protocoloValidade: null,
    validadeInicial: null,
    validadeFinal: null
  }
  tipos: TipoDocumento[] = [];

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    if(isFiltro == true){
      this.options.page = 1;
      this.sheet = false;
      isFiltro = false;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.ListarDocumentos(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro).then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (
      this.loading = false
    ));
  }

  mounted(){
    this.HeaderCliente();
    new TipoDocumentoService().ListarTudo().then(
      res => {
        this.tipos = res.data.items;
      }
    )
  }

  HeaderCliente(){    
    if( !(this.app.role == 'Admin' || this.app.role == 'Arquivista' || this.app.role == 'ResiduoArquivista') ){
      this.headers.splice(0,1);
    }   
  }

  AbrirDialogCadastro(item?: EmpresaDocumento){
    if(item){
      this.service.ObterDocumentoPorId(item.id).then(
        res=>{
          this.item = new EmpresaDocumento(res.data);
          this.item.documento.dataValidade = item.documento.dataValidade ? item.documento.dataValidade.toDateYYYYMMDD() : '';
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
    else{
      this.item = new EmpresaDocumento();
      this.item.empresaId = this.app.empresaId;
      this.item.documento.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
    }
  }

  Excluir(item: EmpresaDocumento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.DeletarDocumento(item.id).then(
          res => {
            if (res.status == 200){
              return res.data;
            }
          },
          err => {
            if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
        )
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
        if(result.value) {
          this.$swal("Aviso", result.value, "success");
          this.Atualizar();
        }
      })
  }

  Download(item: EmpresaDocumento) {
    this.loadingDownload = true;
    this.service.DownloadDocumento(item.id).then(
      async (res) => {

        if (res.status == 204){
          return;
        }

        const arquivoService = new ArquivoService();
        await arquivoService.Download(res.data, res.headers['content-type'], item.documento.arquivo!.nome);

      },
      (err) => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingDownload = false;
    });
  }
}

